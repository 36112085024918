import { ASSET_PREFIX } from "utils/constants";
import { SocialLink } from "./SocialLink";

export const SocialLinkSection = () => {  
    return (
      <div className="flex flex-col gap-2 w-full">
        <p className="font-black text-[15px] text-center md:text-left text-[#4A4A4A] m-0">
          Follow us
        </p>
        <div className="w-full flex justify-center md:justify-start gap-2">
          <SocialLink
            id="facebookext"
            href="https://www.facebook.com/daymadeuk/"
            image={`${ASSET_PREFIX}/images/social-media-icons/facebook-circle.svg`}
            alt="Facebook icon"
          />

          <SocialLink
            id="instagramext"
            href="https://www.instagram.com/daymadeuk/"
            image={`${ASSET_PREFIX}/images/social-media-icons/instagram-circle.svg`}
            alt="Instagram icon"
          />

          <SocialLink
            id="youtubeext"
            href="https://www.youtube.com/channel/UCUVOk8dVM_mu-1qgKwtn9HA"
            image={`${ASSET_PREFIX}/images/social-media-icons/youtube-circle.svg`}
            alt="YouTube icon"
          />

          <SocialLink
            id={'tiktokext'}
            href={'https://www.tiktok.com/@daymadeuk'}
            image={`${ASSET_PREFIX}/images/social-media-icons/tiktok-circle.svg`}
            alt="TikTok icon"
          />
        </div>
      </div>
    );
}