import { ASSET_PREFIX } from 'utils/constants';

const HEIGHT = '50px';
export const TrustIconSection = () => {
  return (
    <div className="w-full flex flex-wrap justify-center md:justify-between gap-x-12 gap-y-10 md:px-0">
      <a
        target="_blank"
        style={{ margin: 0 }}
        href="https://www.trustpilot.com/review/daymade.co.uk"
      >
        <img
          style={{ height: HEIGHT }}
          src={`${ASSET_PREFIX}/images/trustpilot/trust_pilot.svg`}
          alt="Trustpilot logo"
        />
      </a>
      <a
        href="https://www.fundraisingregulator.org.uk/directory/daymade-ltd"
        target="_blank"
        style={{ margin: 0 }}
        rel="noreferrer"
      >
        <img
          src="https://www.fundraisingregulator.org.uk/fr-badge/9ffd3466-af45-4a18-b008-19682f58f076/en/colour"
          style={{ height: HEIGHT }}
          alt="Fundraising Regulator badge with validation link"
        />
      </a>

      <a
        href="https://www.gamcare.org.uk"
        target="_blank"
        style={{ margin: 0 }}
        rel="noreferrer"
      >
        <img
          src={`${ASSET_PREFIX}/images/gamcare-logo.svg`}
          style={{ height: HEIGHT }}
          alt="GamCare logo"
        />
      </a>
      <img
        src={`${ASSET_PREFIX}/images/footer/18-plus.svg`}
        style={{ height: HEIGHT }}
        alt="GamCare logo"
      />
    </div>
  );
};
