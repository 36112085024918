import { useEffect, useRef } from 'react';

export function InstagramEmbed({ videoLink }: { videoLink: string }) {
  const containerRef = useRef(null);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const existingScript = document.getElementById('instagram-embed-script');
    if (!existingScript) {
      const script = document.createElement('script');
      script.setAttribute('src', 'https://www.instagram.com/embed.js');
      script.setAttribute('id', 'instagram-embed-script');
      script.async = true;
      script.onload = () => {
        // @ts-ignore
        if (window.instgrm) {
          // @ts-ignore
          window.instgrm.Embeds.process();
        }
      };
      document.head.appendChild(script);
    } else {
      // Script already present, so try to process immediately
      // @ts-ignore
      if (window.instgrm) {
        // @ts-ignore
        window.instgrm.Embeds.process();
      }
    }
  }, [videoLink]);

  return (
    <div
      className="overflow-y-scroll w-full h-3/4 md:h-full md:w-1/2 "
      ref={containerRef}
    >
      <blockquote
        className="instagram-media"
        data-instgrm-permalink={videoLink}
        data-instgrm-version="14"
        style={{
          background: '#FFF',
          border: 0,
          margin: '30px',
          width: '100%',
        }}
      />
    </div>
  );
}
