import React from 'react';
import { convertToEmbedLink } from 'utils/video/convertToEmbedLink';
import { InstagramEmbed } from './InstagramEmbed';

interface VideoOverlayProps {
  showVideoOverlay: boolean; // Controls whether the overlay is visible
  onClose: () => void; // Function to toggle the overlay
  videoUrl: string | null; // The YouTube video URL
  videoType: 'instagram' | 'youtube' | null
}

const VideoOverlay: React.FC<VideoOverlayProps> = ({
  showVideoOverlay,
  onClose,
  videoUrl,
  videoType
}) => {
  if (!showVideoOverlay || !videoUrl || !videoType) return null; // Don't render anything if the overlay isn't active

  return (
    <div
      onClick={() => onClose()} // Close the overlay on click
      style={{
        zIndex: 1000,
      }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
    >
      <div className="w-full h-full md:w-2/3 relative flex items-center justify-center">
        {/* YouTube Video Embed */}
        {videoType === 'youtube' && (
          <div className="aspect-video w-full rounded shadow-lg overflow-hidden">
            <iframe
              className="w-full h-full"
              src={convertToEmbedLink(videoUrl)}
              title="YouTube Video"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        )}
        {/* Instagram Embed */}
        {videoType === 'instagram' && (
          <div className="aspect-video w-full h-full flex items-center justify-center rounded ">
            <InstagramEmbed videoLink={videoUrl}/>
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoOverlay;