import Separator from "components/Separator";
import Link from "next/link";
import { ITicket } from "types/Ticket";
import { User } from "types/User";
import SnoozeSwitchSection from "../SnoozeSwitchSection";
import { SnoozeSubscriptionRegular } from "./SnoozeSubscriptionRegular";
import { WelcomePlayerSection } from "./WelcomePlayerSection";

import AccountsMenuStatsSection from "../AccountsMenuStatsSection";
import { AccountHeaderSection } from "./AccountHeaderSection";
import { EntriesSection } from "./EntriesSection";


export const AccountMenu = ({
  isOpen,
  showNewDesign,
  currentUser,
  welcomeBackPlanName,
  tickets,
  handleNavigate,
  signOutOnClick,
}: {
  isOpen: boolean;
  showNewDesign: boolean;
  currentUser: User;
  welcomeBackPlanName: string | null;
  tickets: ITicket[]
  handleNavigate: () => void;
  signOutOnClick: () => void;
}) => {
  return (
      <div style={{ display: 'flex' }}>
        <div className={`w-full  flex-col justify-center items-center`}>
          <Separator
            style={{
              width: '98%',
              margin: '0',
              height: '2px',
            }}
          />
          {showNewDesign ? (
            <>
              <WelcomePlayerSection currentUser={currentUser} />
              {['LITE', 'PREMIUM'].includes(currentUser.subscriberStatus) && (
                <SnoozeSwitchSection
                  style={undefined}
                  SwitchComponent={SnoozeSubscriptionRegular}
                />
              )}

              {['LITE', 'PREMIUM'].includes(currentUser.subscriberStatus) &&
                tickets?.length > 0 && <EntriesSection tickets={tickets} />}
            </>
          ) : (
            <>
              <div className="w-full p-[1em] pb-0">
                <AccountHeaderSection
                  currentUser={currentUser}
                  welcomeBackPlanName={welcomeBackPlanName}
                />

                <Separator
                  style={{
                    width: '100%',
                    height: '2px',
                    // margin: '1em !important',
                  }}
                />

                <AccountsMenuStatsSection />
                <Separator
                  style={{
                    width: '100%',
                    height: '2px',
                  }}
                />
              </div>
            </>
          )}

          {showNewDesign ? (
            <>
              <div className="pt-[1em] px-[1em] flexLeftAligned fullWidth">
                <Link href="/dashboard">
                  <a
                    onClick={() => {
                      handleNavigate();
                    }}
                    className="menuLink !px-0 !mt-0"
                  >
                    My Dashboard
                  </a>
                </Link>

                <Link href="/leaderboard">
                  <a
                    onClick={() => {
                      handleNavigate();
                    }}
                    className="menuLink !px-0"
                  >
                    Leaderboard
                  </a>
                </Link>
                <Link href="/token-town">
                  <a
                    onClick={() => {
                      handleNavigate();
                    }}
                    className="menuLink !px-0"
                  >
                    Token Town
                  </a>
                </Link>
                <Link href="/my-account">
                  <a
                    onClick={() => {
                      handleNavigate();
                    }}
                    className="menuLink !px-0 !mb-0"
                  >
                    My Account
                    {currentUser?.claimablePrizesCount > 0 && (
                      <span
                        style={{
                          fontWeight: 700,
                          fontSize: '0.9em',
                          backgroundColor: 'red',
                          color: 'white',
                          borderRadius: 5000,
                          padding: '3px 10px',
                          minWidth: 20,
                          letterSpacing: 0,
                          marginLeft: 10,
                          whiteSpace: 'nowrap',
                          textTransform: 'uppercase',
                        }}
                      >
                        unclaimed prize
                      </span>
                    )}
                  </a>
                </Link>
              </div>
            </>
          ) : (
            <div className="px-[1em] flexLeftAligned w-full">
              <Link href="/account">
                <a className="menuLink !px-0">
                  My account
                  {currentUser?.claimablePrizesCount > 0 && (
                    <span
                      style={{
                        fontWeight: 700,
                        fontSize: '0.9em',
                        backgroundColor: 'red',
                        color: 'white',
                        borderRadius: 5000,
                        padding: '3px 10px',
                        minWidth: 20,
                        letterSpacing: 0,
                        marginLeft: 10,
                        whiteSpace: 'nowrap',
                        textTransform: 'uppercase',
                      }}
                    >
                      unclaimed prize
                    </span>
                  )}
                </a>
              </Link>

              <Link href="/account/wish-list">
                <a className="menuLink !px-0">My wishlist</a>
              </Link>

              <Link href="/leaderboard">
                <a className="menuLink !px-0">Leaderboard</a>
              </Link>

              <Link href="/account/referrals">
                <a className="menuLink !px-0">REFER & EARN £12</a>
              </Link>
            </div>
          )}

          <div className="w-full px-[1em]">
            <Separator
              style={{
                height: '2px',
              }}
            />
          </div>

          <div className="px-[1em] flexLeftAligned fullWidth">
            <a
              className={`menuLink !px-0 ${showNewDesign ? '!mt-0' : ''}`}
              onClick={signOutOnClick}
            >
              Sign out
            </a>
          </div>
        </div>
      </div>
  );
};