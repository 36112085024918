export function convertToEmbedLink(youtubeUrl: string) {
  const url = new URL(youtubeUrl);
  if (url.hostname === 'www.youtube.com' && url.searchParams.has('v')) {
    const videoId = url.searchParams.get('v');
    return `https://www.youtube.com/embed/${videoId}?autoplay=1`;
  } else if (url.hostname === 'youtu.be') {
    const videoId = url.pathname.slice(1);
    return `https://www.youtube.com/embed/${videoId}?autoplay=1`;
  } else {
    throw new Error('Invalid YouTube URL');
  }
}
