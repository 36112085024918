import {
  makeAutoObservable,
  action,
  onBecomeObserved,
  onBecomeUnobserved,
  observable,
  reaction,
} from 'mobx';
import { ActivityFeedItem } from 'types/ActivityFeedItem';
import { fetchActivityFeedItems } from 'utils/api/activity-feed';
import { ACTIVTIY_FEED_FETCH_INTERVAL, MINIMUM_EVENT_AGE } from 'utils/dashboard/constants';
import { RootStore } from './RootStore';

export class ActivityFeedStore {
  root: RootStore;
  displayedItems: ActivityFeedItem[] = [];
  queue: ActivityFeedItem[] = [];
  interval: NodeJS.Timeout | null = null;
  lastEventId: number | null = null;
  isFetching: boolean = false;

  constructor(root: RootStore) {
    this.root = root;

    makeAutoObservable(this, {
      displayedItems: observable,
      stopFetching: action,
      startFetching: action,
    });

    onBecomeObserved(this, 'displayedItems', this.startFetching);
    onBecomeUnobserved(this, 'displayedItems', this.stopFetching);
  }

  /** Starts data fetching and updates */
  startFetching = async () => {
    if (this.interval) {
      console.log('Already started fetching activity feed items');
      return;
    }
    console.log('ActivityFeedStore: Starting data fetching');
    await this.fetchInitialItems();
    await this.fetchNextItems();
    this.startQueueHandler();
    this.interval = setInterval(
      this.fetchNextItems,
      ACTIVTIY_FEED_FETCH_INTERVAL
    );
  };

  /** Stops data fetching and clears data */
  stopFetching = () => {
    console.log('ActivityFeedStore: Stopping data fetching');
    this.setDisplayedItems([]);
    this.setLastEventId(null);
    this.isFetching = false;
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  };

  startQueueHandler = () => {
    reaction(
      () => ({
        queue: this.queue,
      }),
      async ({ queue }) => {
        this.onChangeQueue(queue);
      }
    );
  };

  /** Fetches the initial 4 items */
  fetchInitialItems = async () => {
    if (this.isFetching) return;
    this.isFetching = true;
    const result = await fetchActivityFeedItems();
    if (result.successful && result.feedItems.length > 0) {
      this.setDisplayedItems(result.feedItems);
      this.setLastEventId(result.feedItems[0].id);
      this.isFetching = false;
    } else {
      this.isFetching = false;
    }
  };

  fetchNextItems = async () => {
    if (this.isFetching || !this.lastEventId) return;
    this.isFetching = true;
    const result = await fetchActivityFeedItems(this.lastEventId);
    if (result.successful && result.feedItems.length > 0) {
      this.queue = result.feedItems;
      this.setLastEventId(result.feedItems[0].id);
      this.isFetching = false;
    } else {
      this.isFetching = false;
    }
  };

  onChangeQueue = (queue: ActivityFeedItem[]) => {
    if (queue.length === 0) {
      return;
    }
    for (let i = 0; i < queue.length; i++) {
      const eventTimestamp = new Date(queue[i].timestamp);
      const now = new Date();
      const eventAgeMs = now.getTime() - eventTimestamp.getTime();

      if (eventAgeMs < MINIMUM_EVENT_AGE) {
        const remainingTimeMs = MINIMUM_EVENT_AGE - eventAgeMs;
        setTimeout(() => this.updateDisplayedItems(queue[i]), remainingTimeMs);
      } else {
        this.updateDisplayedItems(queue[i]);
      }

      if (i === 0) {
        // SET to latest timestamp in the queue
        this.setLastEventId(this.queue[i].id);
      }
    }
    this.queue = [];
  };

  updateDisplayedItems = async (activityItem: ActivityFeedItem) => {
    // Add the next item to the top
    this.setDisplayedItems([
      activityItem,
      ...this.displayedItems.slice(0, this.displayedItems.length - 1),
    ]);
    console.log('updating display', this.interval);
  };

  setDisplayedItems = (items: ActivityFeedItem[]) => {
    this.displayedItems = items;
  };

  setLastEventId = (lastEventId: number | null) => {
    this.lastEventId = lastEventId;
  };
}
