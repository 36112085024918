import { action, makeObservable, observable, reaction } from 'mobx';
import Router from 'next/router';
import { EnterFlowProgress } from 'types/ui/EnterFlowProgress';
import { HeaderBanner } from 'types/ui/HeaderBanner';
import { Toast } from 'types/ui/Toast';
import { TEAM_STATUS } from 'utils/admin/status/TeamSttatus';
import { RootStore } from './RootStore';

export type UIHydration = {
  toast?: Toast;
  headerBanner?: HeaderBanner;
  drawCounterInHeader?: boolean
};

export const DASHBOARD_LINK = '/dashboard'

const ACCOUNT_PAGE_LINKS = {
  NEW: '/my-account',
  OLD: '/account'
}

export class UIStore {
  root: RootStore;
  toast: Toast | null = null;
  headerBanner: HeaderBanner | null = null;
  drawCounterInHeader: boolean = false;
  enterFlowProgress: EnterFlowProgress | null = null;
  showVideoOverlay: boolean = false;
  videoOverlayType: 'instagram' | 'youtube' | null = null
  videoOverlayLink: string | null = null;
  isDashboardTestActive: boolean = false;

  private toastTimeout?: ReturnType<typeof setTimeout>;

  constructor(root: RootStore) {
    this.root = root;
    // eslint-disable-next-line mobx/exhaustive-make-observable
    makeObservable(this, {
      displayToast: action,
      displayHeaderBanner: action,
      toast: observable,
      headerBanner: observable,
      drawCounterInHeader: observable,
      enterFlowProgress: observable,
      videoOverlayLink: observable,
      videoOverlayType: observable,
      showVideoOverlay: observable,
      showDrawCounterInHeader: action,
      setEnterFlowProgress: action,
      setShowVideoOverlay: action,
      setVideoOverlayLink: action,
      removeVideoOverlayLink: action,
      navigateToDashboard: action,
      getAccountLink: action,

      isDashboardTestActive: observable,
    });

    reaction(
      () => this.root.userStore.currentUser,
      (currentUser) => {
        if (currentUser) {
          this.isDashboardTestActive = (currentUser.abTestVariants?.['DashboardTest'] === 'TEST' || TEAM_STATUS.includes(currentUser.status));
        }
      }
    );
  }

  displayToast = (toast: Toast | null) => {
    this.toast = toast;
    if (this.toastTimeout) {
      clearTimeout(this.toastTimeout);
    }
    if (toast) {
      this.toastTimeout = setTimeout(() => {
        this.toast = null;
        this.toastTimeout = undefined;
      }, toast.timeout);

      return this.toastTimeout;
    }
  };

  displayHeaderBanner = (banner: HeaderBanner | null) => {
    this.headerBanner = banner;
  };

  showDrawCounterInHeader = (show: boolean) => {
    this.drawCounterInHeader = show;
  };

  setEnterFlowProgress = (enterFlowProgress: EnterFlowProgress) => {
    this.enterFlowProgress = enterFlowProgress;
  };

  setShowVideoOverlay = (show?: boolean) => {
    this.showVideoOverlay = show ?? !this.showVideoOverlay;
  };

  setVideoOverlayLink = (link: string) => {
    this.videoOverlayLink = link;
  };

  setVideoOverlayType = (type: 'instagram' | 'youtube') => {
    this.videoOverlayType = type
  }

  removeVideoOverlayLink = () => {
    this.videoOverlayLink = null;
  };


  getAccountLink = () => {
    return this.isDashboardTestActive ? ACCOUNT_PAGE_LINKS.NEW : ACCOUNT_PAGE_LINKS.OLD;
  }

  navigateToDashboard = () => {
    Router.push(this.isDashboardTestActive ?  DASHBOARD_LINK : ACCOUNT_PAGE_LINKS.OLD);
  }

  navigateToAccount = () => {
    Router.push(this.getAccountLink());
  }


  hydrate(data?: UIHydration) {
    if (data?.toast) {
      this.toast = data.toast;
    }
    if (data?.headerBanner) {
      this.headerBanner = data.headerBanner;
    }
    if (data?.drawCounterInHeader) {
      this.drawCounterInHeader = data.drawCounterInHeader;
    }
  }
}
