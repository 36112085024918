import Link from 'next/link';
import { Icon, SemanticICONS } from 'semantic-ui-react';
import type { HeaderBanner } from 'types/ui/HeaderBanner';

interface IHeaderBannerProps {
  bannerContent: HeaderBanner;
  tappable?: boolean;
}

const HeaderBanner = ({
  bannerContent,
  tappable = false,
}: IHeaderBannerProps) => {
  const renderBannerContent = () => (
    <div className="bannerContainer">
      <div
        className="textContainer"
        onClick={() => bannerContent.tapHandler && bannerContent.tapHandler()}
      >
        {bannerContent.faIconName && (
          <Icon
            name={bannerContent.faIconName as SemanticICONS}
            style={{
              margin: '0.3em 0.5em 0.3em 0.3em',
              fontSize: '2em',
              display: 'inline-flex',
              height: 'auto',
            }}
          />
        )}

        <div style={{ textAlign: 'center' }}>{bannerContent.content}</div>
      </div>

      <style jsx>{`
        .bannerContainer {
          background-color: #4aece1;
          padding: 0.8em;
          font-weight: 600;
          cursor: ${tappable ? 'pointer' : 'default'};
          width: 100%;
        }

        .textContainer {
          display: flex;
          align-items: center;
          justify-content: center;
          max-width: 900px;
          margin-left: auto;
          margin-right: auto;
          text-align: center;
        }
      `}</style>
    </div>
  );

  return bannerContent.href ? (
    <Link href={bannerContent.href}>{renderBannerContent()}</Link>
  ) : (
    renderBannerContent()
  );
};

export default HeaderBanner;
