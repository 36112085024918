import { ASSET_PREFIX } from 'utils/constants';
import PopupWithDimmedBackground from './PopupWithDimmedBackground';

export const OutsideUKPopup = ({ onClose }: { onClose: () => void }) => {
  return (
    <PopupWithDimmedBackground
      additionalClassName={'w-2/3'}
      whiteCloseButton={undefined}
      icon={undefined}
      bannerImage={undefined}
      onClose={onClose}
    >
      <div className="w-full h-full flex flex-col justify-center items-center">
        <img
          className="uk-only-image"
          src={`${ASSET_PREFIX}/images/uk-only.png`}
          width="100px"
        />
        <h3
          style={{
            fontWeight: 800,
            letterSpacing: 'normal',
            textShadow: '2px 2px white',
          }}
        >
          It looks like you are visiting us from outside the United Kingdom
        </h3>
        <p>
          Please note that DAYMADE operates exclusively in the UK and is
          available to UK residents only. We hope to bring the DAYMADE
          experience to your country one day!
        </p>
      </div>

      <style jsx>
        {`
          .uk-only-image {
            width: 100px;
          }

          @media (max-width: 450px) {
            .uk-only-image {
              width: 70px;
            }
          }
        `}
      </style>
    </PopupWithDimmedBackground>
  );
};
