import { performFetchRequest } from "./common";

export const getCurrentLeaderboardDraw =
  async (): Promise<ILeaderboardDraw> => {
    return await performFetchRequest(
      '/leaderboard/current-draw',
      undefined,
      false,
      true
    );
  };

export const getLeaderboardData = async (): Promise<ILeaderboardData[]> => {
  return await performFetchRequest('/leaderboard', undefined, false, true);
};

export const getPointsBreakdown = async (
  jwt: string
): Promise<ILeaderboardsPoints[]> => {
  return await performFetchRequest('/leaderboard/points-breakdown', {
    headers: {
      'x-access-token': jwt,
    },
  });
};

export const getPointsTotal = async (jwt: string) => {
  return await performFetchRequest('/leaderboard/points-total', {
    headers: {
      'x-access-token': jwt,
    },
  });
};

export const getCurrentPositionAndPoints = async (jwt: string) => {
  return await performFetchRequest('/leaderboard/current-position-and-points', {
    headers: {
      'x-access-token': jwt,
    },
  });
};

export const changeUsername = async (jwt: string, newUsername: string) => {
  if (typeof jwt === 'undefined') {
    return {
      successful: false,
      reason: 'No JWT provided',
    };
  }

  return await performFetchRequest('/leaderboard/change-username', {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      'x-access-token': jwt,
    }),
    body: JSON.stringify({
      newUsername,
    }),
  });
};

export const updateLeaderboardImageLocation = async (
  jwt: string,
  location: string
) => {
  return await performFetchRequest('/leaderboard/update-image-location', {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      'x-access-token': jwt,
    }),
    body: JSON.stringify({
      location,
    }),
  });
}

export const uploadPhoto = async (jwt: string, file: File) => {
  const formData = new FormData();
  formData.append('photo', file, file.name);

  return await performFetchRequest('/leaderboard/upload-photo', {
    method: 'POST',
    headers: new Headers({
      'x-access-token': jwt,
    }),
    body: formData,
  });
};

export const removePhoto = async (jwt: string) => {
  return await performFetchRequest('/leaderboard/remove-photo', {
    method: 'POST',
    headers: new Headers({
      'x-access-token': jwt,
    }),
  });
};

// Leaderboard draw operation.

// Used by operators operating a leaderboard draw from admin console.
// 1. Fetches summary of all draws (used for dropdown menu).
// 2. Fetches details about the draw to select by default.
//      - If one of the draw's happens today, return that draw.
//      - Otherwise, return the latest draw.
export const fetchLeaderboardDrawsSummaryAndLatestDraw = async (
  jwt: string
) => {
  return await performFetchRequest('/leaderboard/draws-summary-and-latest', {
    method: 'GET',
    headers: {
      'x-access-token': jwt,
    },
  });
};

export const prepareLeaderboardDraw = async (jwt: string) => {
  return await performFetchRequest('/leaderboard/prepare-draw', {
    method: 'POST',
    headers: {
      'x-access-token': jwt,
    },
  });
};

export const runLeaderboardDraw = async (jwt: string) => {
  return await performFetchRequest('/leaderboard/run-draw', {
    method: 'POST',
    headers: {
      'x-access-token': jwt,
    },
  });
};

export const completeLeaderboardDraw = async (jwt: string) => {
  return await performFetchRequest('/leaderboard/complete-draw', {
    method: 'POST',
    headers: {
      'x-access-token': jwt,
    },
  });
};

export const notifyLeaderboardParticipants = async (
  jwt: string,
  drawId: string
) => {
  return await performFetchRequest(
    `/leaderboard/notify-participants?drawId=${drawId}`,
    {
      method: 'POST',
      headers: {
        'x-access-token': jwt,
      },
    }
  );
};

export const fetchLastMonthsWinners = async (): Promise<
  ILeaderboardWinner[]
> => {
  return await performFetchRequest(
    '/leaderboard/last-months-winners',
    undefined,
    false,
    true
  );
};
