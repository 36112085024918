import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
  when,
} from 'mobx';
import { RootStore } from './RootStore';
import { TDrawDays } from 'types/Subscription';
import {
  validatePromoCode,
  validatePromoCodeUnauthenticated,
} from 'utils/api/discount';
import {
  PromoCodeRewards,
  bundleRequirementsCodeFromEntryMethod,
} from 'components/enter/PaymentSection';
import { EntryMethod } from 'components/enter/EntryMethod';
import { getCookie, removeCookie } from 'utils/cookie';
import { PaymentSchedule } from 'utils/constants';
import { IPromoCodeApplicationResult } from 'types/PromoCode';
import { lineIsValid } from 'utils/TicketValidation';
import {
  formattedPriceStringFromMicroCurrency,
} from 'utils/common';
import { getRequirementsCodeDescription } from 'utils/promo-code-utils';
import { generateRandomNumbers } from 'utils/TicketUtil';
import {
  EecProduct,
  fetchUtmParamsJoinedWithFbDataFromLocalStorage,
  trackEecAddToCart,
  trackEecCheckoutStep1,
  trackEecCheckoutStep2,
  trackEecCheckoutStep4,
  trackEvent,
  trackInitiateCheckoutEvent,
  trackPurchase,
} from 'utils/analytics/analytics';
import { bundlesForEntryMethod, calculateLottoOrderCost } from 'utils/LottoOrderCostCalculator';
import { EnterFlowFeature } from 'components/enter/constants/EnterFlowFeature';
import { getInvitedByDetails } from 'utils/api/bonus';
import { ReferralBonusScheme } from 'utils/ReferralBonusScheme';
import { navigateToConfirmation } from 'utils/navigation';
import { TicketOrder } from 'types/OrderDetails';
import md5 from 'md5';
import { createOrder, createSubscriptionDetailsObject, getOrderDetails } from 'utils/api/order';
import { OrderType } from 'components/payments/PayPalAdyenCheckout';
import Router from 'next/router';

interface IEntryLine {
  main: (number | null)[];
  bonus: number | null;
}

const emptyLine = { main: [null, null, null, null], bonus: null };

export class EnterStore {
  root: RootStore;

  // Observable properties
  initialised: boolean = false;
  initialised_promocode: boolean = false;
  checked_user_referral: boolean = false;
  confirmed: boolean = false;
  entryMethod: keyof typeof EntryMethod = 'PremiumSubscription';
  num_entries: number = 1;
  lines: IEntryLine[] = [];
  days: TDrawDays = 'TUESDAY';
  paymentSchedule = PaymentSchedule.WEEKLY;
  boosted: boolean = false;
  promo_code: string | null = null;
  upgradePageSource = false;
  promoCodeApplicationResult: IPromoCodeApplicationResult | null = null;
  discountMultiplier: number = 0;
  discountMicroAmount: number = 0;
  discountBannerText: string | null = null;
  regularOrderCost: number = 0;
  payNowCost: number = 0;
  orderId: string | null = null;
  adyenPaymentSession: string | null = null;
  orderError: string | null = null;
  adyenPaymentSessionTime: number | null = null;
  loaded: boolean = false;
  isReferralFlow: boolean = false;
  loadingReferralBonusScheme: boolean = false;
  hiddenFeatures: string[] = [];

  constructor(root: RootStore) {
    this.root = root;

    makeObservable(this, {
      // Observables
      initialised: observable,
      initialised_promocode: observable,
      checked_user_referral: observable,
      confirmed: observable,
      entryMethod: observable,
      num_entries: observable,
      lines: observable,
      days: observable,
      paymentSchedule: observable,
      boosted: observable,
      promo_code: observable,
      promoCodeApplicationResult: observable,
      discountMultiplier: observable,
      discountMicroAmount: observable,
      discountBannerText: observable,
      regularOrderCost: observable,
      payNowCost: observable,
      orderId: observable,
      adyenPaymentSession: observable,
      adyenPaymentSessionTime: observable,
      orderError: observable,
      loaded: observable,
      isReferralFlow: observable,
      loadingReferralBonusScheme: observable,
      hiddenFeatures: observable,

      // Actions
      setInitialised: action,
      setInitialised_promocode: action,
      setCheckedUserReferral: action,
      setEntryMethod: action,
      setNumEntries: action,
      setLines: action,
      setLine: action,
      toggleLineNumber: action,
      toggleLineBonusNumber: action,
      generateLineRandomNumbers: action,
      generateRandomNumbers: action,
      removeLine: action,
      setLineNumber: action,
      setDays: action,
      toggleDay: action,
      setBoosted: action,
      setPromoCode: action,
      setPromoCodeApplicationResult: action,
      processPromoCode: action,
      setOrder: action,
      setOrderError: action,
      setOrderCost: action,
      resetOrder: action,
      setConfirmed: action,
      validateOrder: action,
      setLoaded: action,
      chooseNumbers: action,
      navigateToEnterTest: action,
      navigateToCheckout: action,

      // Computed values
      enabledDays: computed,
      isPaidSubscription: computed,
      discountFirstEntry: computed,
      firstEntryDiscount: computed,
      isValid: computed,
      entryMethodBundles: computed,
      promoCodeMessages: computed,
      bundles: computed,
      ballRanges: computed,
      usingBonusBall: computed,
      currentUser: computed,
      eligibleForIntroOffer: computed,
      haveValidAdyenSession: computed,
    });

    this.initReactions();
    this.fetchReferralDetails();
  }

  getData = () => {
    const persistAttributes = [
      'confirmed',
      'entryMethod',
      'num_entries',
      'lines',
      'days',
      'paymentSchedule',
      'boosted',
      'promo_code',
      'upgradePageSource',
      'discountMultiplier',
      'discountMicroAmount',
      'discountBannerText',
      'regularOrderCost',
      'payNowCost',
      'orderId',
      'adyenPaymentSession',
      'adyenPaymentSessionTime',
      'orderError',
    ];
    return Object.fromEntries(
      persistAttributes.map((x) => [x, this[x as keyof EnterStore]])
    );
  };

  initReactions() {
    // Reaction for initial loading
    reaction(
      () => ({
        loadingReferralBonusScheme: this.loadingReferralBonusScheme,
        isReferralFlow: this.isReferralFlow,
        loaded: this.loaded,
        ballRanges: this.ballRanges,
        num_entries: this.num_entries,
      }),
      async ({
        loadingReferralBonusScheme,
        isReferralFlow,
        loaded,
        ballRanges,
        num_entries,
      }) => {
        if (!loadingReferralBonusScheme && !loaded && ballRanges) {
          if (isReferralFlow) {
            trackEvent('isReferralFlow');
            this.setNumEntries(1);
            this.setDays('TUESDAY');
            this.setBoosted(false);
          } else {
            if ([0, 1].includes(num_entries)) {
              this.setNumEntries(1);
            }
            let ext_promo_code = null;
            if (typeof window !== 'undefined') {
              ext_promo_code =
                localStorage.getItem('promoCode') || getCookie('promo_code');
            }
            if (ext_promo_code) {
              await this.setPromoCode(ext_promo_code);
            }
            this.setLoaded(true);
          }
          this.setInitialised_promocode(!loadingReferralBonusScheme);
        }
      }
    );

    // Reaction for loading referral data
    reaction(
      () => ({
        loadingReferralBonusScheme: this.loadingReferralBonusScheme,
        currentUser: this.currentUser,
        isReferralFlow: this.isReferralFlow,
      }),
      async ({ loadingReferralBonusScheme, currentUser, isReferralFlow }) => {
        if (!loadingReferralBonusScheme && currentUser) {
          if (isReferralFlow) {
            const referralId = getCookie('referral_id');
            await this.setPromoCode(referralId);
            this.setLoaded(true);
          }
          this.setCheckedUserReferral(true);
        }
      }
    );

    // Reaction for cost calculation
    reaction(
      () => ({
        loaded: this.loaded,
        num_entries: this.num_entries,
        bundles: this.bundles,
        entryMethod: this.entryMethod,
        firstEntryDiscount: this.firstEntryDiscount,
        days: this.days,
        discountMultiplier: this.discountMultiplier,
        discountMicroAmount: this.discountMicroAmount,
        promoCodeApplicationResult: this.promoCodeApplicationResult,
        boosted: this.boosted,
        isReferralFlow: this.isReferralFlow,
      }),
      async () => {
        if (this.bundles.length > 0) {
          const orderCosts = await calculateLottoOrderCost(
            this.num_entries,
            this.bundles,
            this.entryMethod,
            this.firstEntryDiscount,
            this.days,
            false,
            this.discountMultiplier,
            this.discountMicroAmount,
            this.boosted,
            PaymentSchedule.WEEKLY,
            1
          );

          this.setOrderCost(
            orderCosts.regularOrderCost,
            this.isReferralFlow ? 0 : orderCosts.payNowCost
          );
          if (!this.initialised) {
            this.setInitialised(true);
          }
          this.validateOrder();
        }
      }
    );
  }

  // Computed properties and getters
  get enabledDays() {
    return {
      tuesdayEnabled: this.days === 'TUESDAY' || this.days === 'BOTH',
      fridayEnabled: this.days === 'FRIDAY' || this.days === 'BOTH',
    };
  }

  get isPaidSubscription() {
    return [
      EntryMethod.PremiumSubscription,
      EntryMethod.LiteSubscription,
    ].includes(this.entryMethod);
  }

  get discountFirstEntry() {
    return (
      this.entryMethod === EntryMethod.PremiumSubscription &&
      !this.isReferralFlow &&
      this.eligibleForIntroOffer
    );
  }

  get firstEntryDiscount() {
    return this.discountFirstEntry ? 0.5 : 0;
  }

  get isValid() {
    return this.ballRanges && this.validateOrder();
  }

  get entryMethodBundles() {
    return bundlesForEntryMethod(
      this.bundles,
      this.entryMethod,
      this.paymentSchedule
    );
  }

  get promoCodeMessages() {
    const messages = [];

    if (this.promoCodeApplicationResult) {
      const promoCodeMessage = this.getPromoCodeMessage();
      if (promoCodeMessage) {
        messages.push(promoCodeMessage);
      }
    }

    if (messages.length > 0 && this.firstEntryDiscount) {
      messages.push('Your 1st entry has been discounted by 50%');
    }

    return messages;
  }

  get bundles() {
    return this.root.drawStore.bundles;
  }

  get ballRanges() {
    return this.root.drawStore.ballRanges;
  }

  get usingBonusBall() {
    return this.root.drawStore.usingBonusBall;
  }

  get currentUser() {
    return this.root.userStore.currentUser;
  }

  get eligibleForIntroOffer() {
    return this.root.userStore.eligibleForIntroOffer;
  }

  get haveValidAdyenSession() {
    const tenMinutes = 10 * 60 * 1000;
    return (
      this.adyenPaymentSession &&
      this.adyenPaymentSessionTime &&
      Date.now() - this.adyenPaymentSessionTime < tenMinutes
    );
  }

  // Action methods
  setInitialised = (initialised: boolean) => {
    this.initialised = initialised;
  };

  setInitialised_promocode = (initialised_promocode: boolean) => {
    this.initialised_promocode = initialised_promocode;
  };

  setCheckedUserReferral = (checked_user_referral: boolean) => {
    this.checked_user_referral = checked_user_referral;
  };

  setLoaded = (loaded: boolean) => {
    this.loaded = loaded;
  };

  resetOrder = () => {
    this.orderId = null;
    this.adyenPaymentSession = null;
    this.adyenPaymentSessionTime = null;
    this.orderError = null;
    this.confirmed = false;
  };

  resetEnter = () => {
    this.setNumEntries(0);
    this.setNumEntries(1);
    this.setBoosted(false);
    this.setDays('TUESDAY');
  };

  setEntryMethod = (entryMethod: keyof typeof EntryMethod) => {
    if (this.entryMethod !== entryMethod) {
      this.entryMethod = entryMethod;

      this.resetEnter();

      this.processPromoCode();
      this.resetOrder();
    }
  };

  setNumEntries = (num_entries: number) => {
    console.log('set num_entries', num_entries);
    this.num_entries = num_entries;

    if (this.lines.length > num_entries) {
      this.setLines(this.lines.slice(0, num_entries));
    } else if (this.lines.length < num_entries) {
      while (this.lines.length < num_entries) {
        this.lines.push(this.generateRandomNumbers());
      }
      this.setLines(this.lines);
    }
    this.resetOrder();
  };

  setLines = (lines: IEntryLine[]) => {
    console.log('set lines', lines);
    this.lines = lines;
    this.resetOrder();
  };

  setLine = (line_number: number, line: IEntryLine) => {
    const newLines = this.lines.slice();
    newLines[line_number] = line;
    this.lines = newLines;
    this.resetOrder();
  };

  setLineNumber = (line_number: number, position: number, value: number) => {
    if (line_number >= this.num_entries) {
      throw Error('line_number bigger than num_entries');
    }
    this.lines[line_number]['main'][position] = value;
    this.resetOrder();
  };

  removeLine = (line_number: number) => {
    this.lines.splice(line_number, 1);
    this.setNumEntries(this.num_entries - 1);
  };

  toggleLineNumber = (line_number: number, value: number) => {
    if (line_number >= this.num_entries) {
      console.log(
        'line_number',
        line_number,
        'this.num_entries',
        this.num_entries
      );
      throw Error('line_number bigger than num_entries');
    }

    const haveNum = this.lines[line_number]['main'].indexOf(value);

    if (haveNum !== -1) {
      this.lines[line_number]['main'][haveNum] = null;
    } else {
      const emptySpace = this.lines[line_number]['main'].indexOf(null);

      if (emptySpace !== -1) {
        this.lines[line_number]['main'][emptySpace] = value;
      }
    }
    this.resetOrder();
  };

  toggleLineBonusNumber = (line_number: number, value: number) => {
    if (line_number >= this.num_entries) {
      throw Error('line_number bigger than num_entries');
    }

    if (value === this.lines[line_number]['bonus']) {
      this.lines[line_number]['bonus'] = null;
    } else {
      this.lines[line_number]['bonus'] = value;
    }

    this.resetOrder();
  };

  generateLineRandomNumbers = (line_number: number) => {
    this.setLine(line_number, this.generateRandomNumbers());
  };

  generateRandomNumbers = () => {
    const ballRanges = this.ballRanges;
    const usingBonusBall = this.usingBonusBall;

    if (ballRanges && usingBonusBall) {
      return generateRandomNumbers(ballRanges, usingBonusBall);
    }

    return emptyLine;
  };

  setDays = (days: TDrawDays) => {
    this.days = days;

    this.resetOrder();
  };

  toggleDay = (day: 'TUESDAY' | 'FRIDAY') => {
    const binDays = {
      TUESDAY: this.days === 'BOTH' || this.days === 'TUESDAY',
      FRIDAY: this.days === 'BOTH' || this.days === 'FRIDAY',
    };

    binDays[day] = !binDays[day];

    this.days =
      binDays.TUESDAY && binDays.FRIDAY
        ? 'BOTH'
        : binDays.TUESDAY
        ? 'TUESDAY'
        : 'FRIDAY';
    this.resetOrder();
  };

  setBoosted = (boosted: boolean) => {
    console.log('set boosted', boosted);
    this.boosted = boosted;
    this.resetOrder();
  };

  setPromoCode = async (promo_code: string | null) => {
    this.promo_code = promo_code;
    await this.processPromoCode();
  };

  setPromoCodeApplicationResult = (
    result: IPromoCodeApplicationResult | null
  ) => {
    this.promoCodeApplicationResult = result;

    this.resetOrder();
  };

  processPromoCode = async (bypassPromocode?: string) => {
    const promo = bypassPromocode ? bypassPromocode : this.promo_code;
    if (promo) {
      const jwt = getCookie('jwt');

      const result = jwt
        ? await validatePromoCode(
            jwt,
            promo.toUpperCase(),
            this.lines.length,
            bundleRequirementsCodeFromEntryMethod(this.entryMethod)
          )
        : await validatePromoCodeUnauthenticated(
            promo.toUpperCase(),
            this.lines.length,
            bundleRequirementsCodeFromEntryMethod(this.entryMethod)
          );

      const paidSubscription = this.isPaidSubscription;

      this.discountMultiplier = 0;
      this.discountMicroAmount = 0;
      this.discountBannerText = null;

      if (
        (['SUBSCRIPTION', 'WEEKLY_DRAW_SUBSCRIPTION'].includes(
          result?.rewardRequirementsCode
        ) &&
          !paidSubscription) ||
        result?.rewardRequirementsCode === 'WOWCHER'
      ) {
        this.setPromoCodeApplicationResult({
          successful: false,
          reason: getRequirementsCodeDescription(result.rewardRequirementsCode),
        });
      } else {
        this.setPromoCodeApplicationResult(result);

        if (result?.successful) {
          const { rewardCode } = result;
          switch (rewardCode) {
            case PromoCodeRewards.DISCOUNT_PERCENTAGE:
              const discountPercentage = parseInt(result.rewardValue, 10);
              this.discountMultiplier = discountPercentage / 100;
              break;
            case PromoCodeRewards.DISCOUNT_ENTRY:
              this.discountMicroAmount = result.discountAsMicroAmount;
              this.discountBannerText = 'Promo Applied';
              break;
          }
        }
      }
    }

    this.resetOrder();
  };

  getPromoCodeMessage = () => {
    if (
      !this.promoCodeApplicationResult ||
      !this.promoCodeApplicationResult.successful
    ) {
      return null;
    }
    const { rewardValue, rewardCode, totalRecurrences, discountAsMicroAmount } =
      this.promoCodeApplicationResult;

    switch (rewardCode) {
      case PromoCodeRewards.FREE_ENTRY:
        // @ts-ignore : stupid change of type of rewardValue to list of entries
        const numFreeEntries = rewardValue.length;
        return `Promo code successfully applied. ${numFreeEntries} ${
          numFreeEntries === 1 ? 'free entry ' : 'free entries '
        } will be added.`;

      case PromoCodeRewards.FREE_ENTRY_AND_EXTRA_TREE:
        // @ts-ignore : stupid change of type of rewardValue to list of entries
        const numFreeEntries2 = rewardValue.length;
        return `Promo code successfully applied. ${numFreeEntries2} ${
          numFreeEntries2 === 1 ? 'free entry ' : 'free entries '
        } will be added and you will also plant an additional tree with your purchase!`;

      case PromoCodeRewards.DISCOUNT_PERCENTAGE:
        return `Your promo code has successfully been applied. Your order has been discounted by ${rewardValue}%${
          totalRecurrences && totalRecurrences > 0
            ? ` and will continue to be discounted for ${totalRecurrences} more week${
                totalRecurrences > 1 ? 's' : ''
              }`
            : ''
        }.`;

      case PromoCodeRewards.DISCOUNT_ENTRY:
        return `Your order has been discounted by the cost of ${
          rewardValue === 1 ? 'an entry' : `${rewardValue} entries`
        } (${formattedPriceStringFromMicroCurrency(discountAsMicroAmount)})${
          totalRecurrences && totalRecurrences > 0
            ? ` and will continue to be discounted for ${totalRecurrences} more week${
                totalRecurrences > 1 ? 's' : ''
              }`
            : ''
        }.`;

      case PromoCodeRewards.FREE_1ST_SUBSCRIPTION_ENTRY:
        return `Your promo code has successfully been applied. Your order has been discounted by the cost of one entry.`;

      default:
        console.log(`Don't know what to do with rewardCode: ${rewardCode}`);
    }
  };

  setOrder = (orderId: string, adyenPaymentSession: string) => {
    this.orderId = orderId;
    this.adyenPaymentSession = adyenPaymentSession;
    this.adyenPaymentSessionTime = Date.now();
  };

  handleContinueToPayment = async (
    orderIdRef: React.MutableRefObject<string | null>
  ) => {
    if (this.haveValidAdyenSession) {
      return {
        orderId: this.orderId,
        adyenPaymentSession: this.adyenPaymentSession,
      };
    }

    const jwt = getCookie('jwt') || getCookie('guest_jwt');
    const affiliateFutureAffc = getCookie('Affc', null);

    const subscriptionDetails =
      this.entryMethod === EntryMethod.OneOff
        ? null
        : createSubscriptionDetailsObject(
            this.entryMethod as any,
            this.paymentSchedule === PaymentSchedule.MONTHLY
          );

    const drawDays = this.days === 'BOTH' ? ['TUESDAY', 'FRIDAY'] : [this.days];

    const sourceFor3DS = this.upgradePageSource ? 'UPGRADE' : 'enter-now';

    const utmParams = fetchUtmParamsJoinedWithFbDataFromLocalStorage();

    const result = await createOrder(
      jwt,
      this.lines.map((entry) => [
        ...entry.main.map((n) => n!.toString()),
        String(entry.bonus),
      ]),
      this.payNowCost,
      subscriptionDetails,
      drawDays,
      this.boosted,
      this.promoCodeApplicationResult,
      0,
      utmParams,
      null,
      [],
      affiliateFutureAffc,
      sourceFor3DS
    );

    if (result.successful === false) {
      runInAction(() => {
        this.setPromoCodeApplicationResult(result);
        this.setOrderError(result.reason);
      });
      return;
    }

    trackInitiateCheckoutEvent();

    await trackEecCheckoutStep4(
      EecProduct.LOTTERY,
      this.entryMethod,
      `${this.lines.length} entry`,
      this.regularOrderCost,
      false
    );

    runInAction(() => {
      this.setOrder(result.order.id, result.order.adyenPaymentSession);
      orderIdRef.current = result.order.id;
    });

    return {
      orderId: this.orderId,
      adyenPaymentSession: this.adyenPaymentSession,
    };
  };

  checkOrderStatusAndAdvanceToConfirmation = async (
    orderIdRef: React.MutableRefObject<string | null>,
    orderCompletionTimerRef: React.MutableRefObject<any>
  ) => {
    if (!orderIdRef.current) {
      return;
    }

    const jwt = getCookie('jwt') || getCookie('guest_jwt');
    const orderDetails = await getOrderDetails(
      jwt,
      orderIdRef.current,
      OrderType.TICKET_ORDER
    );

    if (orderDetails?.order?.state === 'COMPLETE') {
      await this.onSuccessfulPayment(orderDetails);
    } else {
      orderCompletionTimerRef.current = setTimeout(
        () =>
          this.checkOrderStatusAndAdvanceToConfirmation(
            orderIdRef,
            orderCompletionTimerRef
          ),
        2000
      );
    }
  };

  onSuccessfulPayment = async (orderDetails: TicketOrder) => {
    removeCookie('referral_id');
    removeCookie('promoCode');

    const promoCodeUsed =
      orderDetails.promoCodeEntries?.length > 0
        ? orderDetails.promoCodeEntries[0].code_used
        : orderDetails.promoCodeDiscount?.length > 0
        ? orderDetails.promoCodeDiscount[0].code_used
        : null;

    await trackPurchase(
      orderDetails.order.payment_cost,
      orderDetails.id,
      orderDetails.ticket,
      orderDetails.ticket2,
      orderDetails.raffleTicket,
      this.entryMethod,
      this.root.userStore.currentUser?.email_address
        ? md5(this.root.userStore.currentUser.email_address)
        : '',
      promoCodeUsed,
      orderDetails.order.firstTimePlayer,
      this.root.userStore.currentUser?.id,
      {
        drawDays: orderDetails.drawDays.join(','),
        numEntries: orderDetails.ticket.entries.length,
        boosted: orderDetails.ticket.boosted || orderDetails.ticket2?.boosted,
        billings:
          parseInt(orderDetails.ticket.billings) +
          parseInt(orderDetails.ticket2?.billings || '0'),
        cost:
          parseInt(orderDetails.ticket.cost) +
          parseInt(orderDetails.ticket2?.cost || '0'),
      }
    );

    await this.root.userStore.updateCurrentUser();
    await navigateToConfirmation(Router, orderDetails.id, true);
  };

  setOrderError = (error: string) => {
    this.orderError = error;
  };

  setOrderCost = (regularCost: number, payNowCost: number) => {
    this.regularOrderCost = regularCost;
    this.payNowCost = payNowCost;
  };

  setConfirmed = () => {
    this.confirmed = true;
  };

  validateOrder = () => {
    const ballRanges = this.ballRanges;
    const usingBonusBall = this.usingBonusBall;

    const hasErrors = this.lines.some(
      (line) =>
        !lineIsValid(
          [...line.main, line.bonus],
          ballRanges ?? [],
          usingBonusBall
        )
    );

    this.confirmed = !hasErrors;
    return !hasErrors;
  };

  chooseNumbers = async () => {
    console.log('chooseNumbers');
    this.setConfirmed();
    await trackEecAddToCart(
      EecProduct.LOTTERY,
      this.entryMethod,
      `${this.lines.length} entry`,
      this.regularOrderCost
    );
    await trackEecCheckoutStep1(
      EecProduct.LOTTERY,
      this.entryMethod,
      `${this.lines.length} entry`,
      this.regularOrderCost
    );
    await trackEecCheckoutStep2();

    if (!this.currentUser) {
      // Authenticate
      Router.push(`/register?redirect=${encodeURIComponent('checkout')}`);
    } else {
      // Go to payment page
      Router.push(`/checkout`);
    }
  };

  setIsReferralFlow = (isReferralFlow: boolean) => {
    this.isReferralFlow = isReferralFlow;
  };

  setLoadingReferralBonusScheme = (loading: boolean) => {
    this.loadingReferralBonusScheme = loading;
  };

  setHiddenFeatures = (features: string[]) => {
    this.hiddenFeatures = features;
  };

  hideFeatures = () => {
    this.setHiddenFeatures([
      EnterFlowFeature.MULTIPLE_ENTRIES,
      EnterFlowFeature.MULTIPLE_DRAWS,
      EnterFlowFeature.BOOST_OR_TOP_UP,
    ]);
  };

  fetchReferralDetails = async () => {
    if (typeof window === 'undefined') {
      return;
    }
    const referralId = Router.query?.ri || getCookie('referral_id');

    if (!referralId) {
      this.setLoadingReferralBonusScheme(false);
      return;
    }

    const invitedByDetails = await getInvitedByDetails(referralId);
    if (!invitedByDetails || invitedByDetails.successful === false) {
      this.setLoadingReferralBonusScheme(false);
      return;
    }

    const { referralBonusScheme } = invitedByDetails;
    const isReferralFlow = [
      ReferralBonusScheme.Give12Get12,
      ReferralBonusScheme.Give6Get6,
      ReferralBonusScheme.LocaliteAndDiscount,
    ].includes(referralBonusScheme);

    this.setIsReferralFlow(isReferralFlow);
    this.setLoadingReferralBonusScheme(false);
    if (isReferralFlow) {
      this.hideFeatures();
    }
  };

  navigateToEnterTest = (enterLink: string) => {
    if (typeof window === 'undefined') {
      return;
    }

    if (this.isReferralFlow) {
      return Router.push('/enter-now');
    }

    return Router.push('/enternow/1');
  };

  navigateToCheckout = (isReferralFlow?: boolean) => {
    if (typeof window === 'undefined') {
      return;
    }

    return Router.push(
      `/register?redirect=${isReferralFlow ? 'checkout' : 'enter/payment'}`
    );
  };
}
