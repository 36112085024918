import { action, makeObservable, observable } from 'mobx';
import { LandingPagePrize, Prize } from 'types/Prize';
import { doABTestWithDistribution } from 'utils/analytics/abtest';
import { fetchTrendingPrizes } from 'utils/api/prizes';
import { RootStore } from './RootStore';

export type PrizeHydration = {
  trendingPrizes?: Prize[];
  landingPagePrizes?: LandingPagePrize[]
};

const TRENDING_PRIZES_CACHE_TTL = 7 * 24 * 60 * 60 * 1000; // 1 week in milliseconds
const SELECTED_TRENDING_PRIZE_TTL = 5 * 60 * 1000; // 5 minutes in milliseconds
const TRENDING_PRIZES_CACHE_KEY = 'trendingPrizes';
const SELECTED_PRIZE_CACHE_KEY = 'selectedTrendingPrize';

export class PrizeStore {
  root: RootStore;
  trendingPrizes?: Prize[] = [];
  landingPagePrizes: LandingPagePrize[] | null = null;
  selectedLandingPagePrize: LandingPagePrize | null = null;
  trendingPrizeSelected?: null | Prize = null;

  constructor(root: RootStore) {
    this.root = root;

    makeObservable(this, {
      trendingPrizes: observable,
      trendingPrizeSelected: observable,
      landingPagePrizes: observable,
      selectedLandingPagePrize: observable,
      setLandingPagePrizeFromUTMContent: action,
    });

    this.initAsync();
  }

  initAsync = async () => {
    if (typeof window === 'undefined') {
      return;
    }
    await this.fetchTrendingPrizes();
    this.selectTrendingPrize();
  };

  getLandingPagePrizeFromUTMContent = (utmContent: string) => {
    const prizeResults = this.landingPagePrizes?.filter((prize) =>
      utmContent.includes(prize.utmContent)
    );

    if (!prizeResults || prizeResults.length === 0) {
      return;
    }

    return prizeResults[0];
  };

  setLandingPagePrizeFromUTMContent = (utmContent: string) => {
    const prizeFound = this.getLandingPagePrizeFromUTMContent(utmContent);

    if (prizeFound) {

      const testGroup = doABTestWithDistribution(
        'DYNAMIC_LANDING_TEST_V1',
        [
          { choice: 'CONTROL', weight: 1 },
          { choice: 'TEST', weight: 0 },
        ]
      );

      this.root.abTestStore.updateABTestValuesFromLocalStorage()

      if (testGroup === 'CONTROL') {
        return;
      }

      this.selectedLandingPagePrize = prizeFound;
    }
  };

  protected fetchTrendingPrizes = async () => {
    if (typeof window === 'undefined') {
      return;
    }

    const now = new Date().getTime();
    const cachedData = localStorage.getItem(TRENDING_PRIZES_CACHE_KEY);

    // Check if data exists and is still valid
    if (cachedData) {
      const { data, expiration } = JSON.parse(cachedData);
      if (data && data?.successful !== false && now < expiration) {
        this.setTrendingPrizes(data);
        return;
      }
    }

    // Cache is expired or doesn't exist, fetch from API
    const fetchedPrizes = await fetchTrendingPrizes();

    this.setTrendingPrizes(fetchedPrizes);

    // Save fetched data and expiration to localStorage
    const newCache = {
      data: fetchedPrizes,
      expiration: now + TRENDING_PRIZES_CACHE_TTL,
    };

    localStorage.setItem(TRENDING_PRIZES_CACHE_KEY, JSON.stringify(newCache));
  };

  setTrendingPrizes = (prizes: Prize[]) => {
    this.trendingPrizes = prizes
  }

  selectTrendingPrize = () => {
    const now = new Date().getTime();
    const cachedPrizeData = localStorage.getItem(SELECTED_PRIZE_CACHE_KEY);

    // If a cached prize exists and is still valid, use it
    if (cachedPrizeData) {
      const { prize, expiration } = JSON.parse(cachedPrizeData);
      if (now < expiration) {
        this.trendingPrizeSelected = prize;
        return;
      }
    }

    // If no valid prize in cache, select a new one randomly from trendingPrizes
    if (this.trendingPrizes && this.trendingPrizes.length > 0) {
      const randomIndex = Math.floor(
        Math.random() * this.trendingPrizes.length
      );
      const newSelectedPrize = this.trendingPrizes[randomIndex];

      this.trendingPrizeSelected = newSelectedPrize;

      // Save the new selected prize with a new expiration of 12 hours
      const newCache = {
        prize: newSelectedPrize,
        expiration: now + SELECTED_TRENDING_PRIZE_TTL,
      };
      localStorage.setItem(SELECTED_PRIZE_CACHE_KEY, JSON.stringify(newCache));
    }
  };

  hydrate(data?: PrizeHydration) {
    if (data && data.trendingPrizes) {
      this.trendingPrizes = data.trendingPrizes;
    }
    if (data && data.landingPagePrizes) {
      this.landingPagePrizes = data.landingPagePrizes;
    }
  }
}
